<template>
  <b-modal
    :id="idModalCreateCarCatalog"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="md"
    title="Thêm phiên bản"
    @hidden="resetState"
    @ok="handleCreateCarCategory"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-form-group>
            <label>Ảnh đại diện <span class="required">(*)</span></label>
            <b-row>
              <b-col
                md="4"
                sm="5"
                xl="4"
                xs="12"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click="handler_open_library(1)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CloudIcon"
                  />
                  Chọn ảnh
                </b-button>
                <library-modal
                  :id-library-model="'id-library-modal'"
                  @handleCloseLibraryModal="handleCloseLibraryModal"
                />
              </b-col>
              <b-col
                class="text-center"
                md="8"
                sm="7"
                xl="8"
                xs="12"
              >
                <img
                  :src="(avatarLink)"
                  alt=""
                  class="img-responsive"
                  style="width: 100%!important;"
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-form-group>
            <label>Tên phiên bản xe <span class="required">(*)</span></label>
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              autocomplete="off"
              autofocus
              placeholder="Nhập tên phiên bản xe ..."
            />
            <small
              v-if="!$v.queryBody.name.required"
              class="text-danger"
            >
              Không được bỏ trống</small>
          </b-form-group>
          <b-form-group>
            <label>Giá bán đề xuất</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.price"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Trước bạ (%)</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.truocBa"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Đăng ký</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.dangKy"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Kiểm định</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.kiemDinh"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Bảo hiểm vật chất</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.baoHiem"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Đường bộ</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.duongBo"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Trách nhiệm dân sự</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.trachNhiem"
              :options="options.number"
              :raw="false"
              autocomplete="off"
              class="form-control text-right"
              placeholder="0"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import { maxLength, required } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    LibraryModal,
  },
  directives: {
    Ripple,
  },
  props: {
    idModalCreateCarCatalog: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataCarCatalogDetail: {
      type: Array,
      default: _ => {
      },
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryBody: {
        image: '',
        name: '',
        price: 0,
        truocBa: 0,
        dangKy: 0,
        kiemDinh: 0,
        baoHiem: 0,
        duongBo: 0,
        trachNhiem: 0,
      },
      avatarLink: '',
      libraryType: 0,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      status: 'not_accepted',
      isStart: false,
    }
  },
  watch: {
    dataCarCatalogDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            image: this.dataCarCatalogDetail.image,
            name: this.dataCarCatalogDetail.name,
            price: this.dataCarCatalogDetail.price,
            truocBa: this.dataCarCatalogDetail.truocBa,
            dangKy: this.dataCarCatalogDetail.dangKy,
            kiemDinh: this.dataCarCatalogDetail.kiemDinh,
            baoHiem: this.dataCarCatalogDetail.baoHiemVatChat,
            duongBo: this.dataCarCatalogDetail.duongBo,
            trachNhiem: this.dataCarCatalogDetail.trachNhiemDanSu,
          }
          if (this.dataCarCatalogDetail.fileViewModal !== null) {
            this.avatarLink = this.callThumbnail(
              this.dataCarCatalogDetail.fileViewModal.fileExtension,
              this.dataCarCatalogDetail.fileViewModal.filePath,
              this.dataCarCatalogDetail.fileViewModal.fileType,
            )
          } else {
            this.avatarLink = ''
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            price: 0,
            isContact: '',
          }
          this.queryBody.image = ''
          this.queryBody.brochure = ''
          this.avatarLink = ''
          this.brochureName = ''
        }
      },
    },
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    ...mapActions('users', ['uploadFile']),
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handleCreateCarCategory(bvModalEvt) {
      this.isStart = true
      bvModalEvt.preventDefault()
      this.$emit('handleCreateCarCategory', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        name: '',
        price: 0,
        isContact: '',
        avatar: '',
        brochure: '',
      }
      this.avatarLink = ''
      this.brochureName = ''
      this.isStart = false
    },
    openInput() {
      this.handler_open_library()
    },
    callThumbnail(fileExtension, filePath, fileType) {
      return NCoreHelper.GetThumbnail(false, fileExtension, filePath, '64x64', this.$isServer, NCoreHelper.GetIsImageLocal(fileType))
    },
    handler_open_library(type) {
      this.libraryType = type
      this.libraryStore_action_ResetBreadCom()
      this.$bvModal.show('id-library-modal')
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        this.avatarLink = this.callThumbnail(val.fileExtension, val.filePath, val.fileType)
        this.queryBody.image = val.id
      }
      this.$bvModal.hide('id-library-modal')
    },
    removeFile(index) {
      //= => Gọi api xóa đi hình ảnh này ======
      this.queryBody.image = null
      this.avatarLink = ''
    },
  },
}
</script>
