var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('n-button-header',{attrs:{"content-btn-add":'Thêm thông số kỹ thuật'},on:{"clickAdd":_vm.handleOpenModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataRows || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"sort-options":{
      enabled: false,
    },"style-class":"vgt-table"},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('user-avatar',{attrs:{"data":props.row}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold text-name"},[_vm._v(_vm._s(props.row.name))])])],1):(props.column.field === 'priceName')?_c('span',[_c('span',{staticClass:"text-nowrap text-right"},[_vm._v(_vm._s(props.row.priceName))])]):(props.column.field === 'actionFunction')?_c('span',[_c('span',{on:{"click":function($event){return _vm.handleOpenModalUpdate(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleOpenModalQuestion(props.row.id, 0)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleViewDetail(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"current-page":_vm.urlQuery.currentPage,"total-items":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('version-detail-event',{attrs:{"data-car-catalog-detail":_vm.dataCarCatalogDetail,"type":_vm.modalType,"id-detail-event":"modalCreate"},on:{"submitEvent":_vm.submitEvent}}),_c('modal-delete-no-param',{attrs:{"content-tile":_vm.modalContent,"type":_vm.modalType,"id-modal-delete-no-param":"idModalQuestion"},on:{"accept":_vm.handleRunQuestionCarCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }