<template>
  <b-modal
    :id="idDetailEvent"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    centered
    no-close-on-backdrop
    ok-title="Lưu"
    size="md"
    title="THÔNG SỐ KỸ THUẬT"
    @hidden="resetState"
    @ok="submitEvent"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <label>1. Loại thông số kỹ thuật</label>
        </b-col>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <group-new-select
            v-model="queryBody.specificationId"
            group-category="5"
            no-title-category="Không có thông số kỹ thuật"
            :parentId="parentId"
            title-category="Thông số kỹ thuật"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <label>2. Giá trị</label>
        </b-col>
        <b-col
          md="12"
          sm="12"
          xl="12"
          xs="12"
        >
          <b-form-input
            id="InputHelp"
            v-model="queryBody.value"
            autofocus
            autocomplete="off"
          />
          <small
            v-if="!$v.queryBody.value.required"
            class="text-danger"
          >
            Không được bỏ trống</small>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import { maxLength, required } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import GroupNewSelect from '@/views/slide-manager/pages/components/GroupNewSelect.vue'

export default {
  components: {
    GroupNewSelect,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    LibraryModal,
  },
  directives: {
    Ripple,
  },
  props: {
    idDetailEvent: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataCarCatalogDetail: {
      type: Array,
      default: _ => {
      },
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      queryBody: {
        specificationId: '',
        value: '',
      },
      isStart: false,
    }
  },
  watch: {
    dataCarCatalogDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            value: this.dataCarCatalogDetail.value,
            specificationId: this.dataCarCatalogDetail.specificationId,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            value: '',
            specificationId: '',
          }
        }
      },
    },
  },
  validations: {
    queryBody: {
      value: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    submitEvent(bvModalEvt) {
      this.isStart = true
      bvModalEvt.preventDefault()
      this.$emit('submitEvent', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        name: '',
        parentId: '',
      }
      this.isStart = false
    },
  },
}
</script>
