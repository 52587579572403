<template lang="">
  <div>
    <tree-select
      v-model="selected"
      :disabled="disableProp"
      :options="dataGroupNewUpdated"
      :normalizer="normalizer"
      :load-options="loadOptions"
      :valueFormat="valueFormat"
      :placeholder="`${titleCategory}`"
      :noChildrenText="`${noTitleCategory}`"
      @input="selectChange"
    >
      <div
        v-if="allowAddNew"
        slot="before-list"
      >
        <div
          class="header-template"
          @click="showAddModal"
        >
          <feather-icon icon="PlusCircleIcon" />
          <span class="btn-text-select">{{
            $t('common.add')
          }}</span>
        </div>
        <div class="border-item-select"></div>
      </div>
    </tree-select>
  </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import TreeSelect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// const simulateAsyncOperation = fn => {
//   setTimeout(fn, 1000)
// }
export default {
  components: {
    TreeSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    allowAddNew: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: 'id',
    },
    parentId: {
      type: String,
      default: '',
    },
    idMinus: {
      type: String,
      default: '',
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    isMinus: {
      type: Boolean,
      default: false,
    },
    groupCategory: {
      type: Number,
      default: 1,
    },
    titleCategory: {
      type: String,
      default: '',
    },
    noTitleCategory: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: '',
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.childrens,
        }
      },
    }
  },
  computed: {
    ...mapGetters('groupNew', ['dataGroupNewUpdated', 'dataGroupNewChildren']),
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== this.selected) {
        this.fetchData()
        this.selected = newValue
      }
    },
  },
  mounted() {
    this.fetchData()
    this.callTitle()
  },
  // created() {
  //   this.fetchData()
  //   // this.doFetchOrganizationChildren(this.id)
  // },
  methods: {
    ...mapActions('groupNew', ['doFetchGroupNewUpdated', 'doFetchGroupNewChildren']),
    async loadOptions({
      action,
      parentNode,
      callback,
    }) {
      // Typically, do the AJAX stuff here.
      // Once the server has responded,
      // assign children options to the parent node & call the callback.
      if (action === LOAD_CHILDREN_OPTIONS) {
        const model = {
          groupId: this.groupCategory,
          id: parentNode.id,
          idMinus: this.idMinus,
          isMinus: this.isMinus,
        }
        await this.doFetchGroupNewChildren(model)
        if (this.dataGroupNewChildren.length > 0) {
          this.dataGroupNewChildren.forEach(element => {
            element.childrens = null
          })
        } else {
          this.dataGroupNewChildren = undefined
        }
        parentNode.childrens = this.dataGroupNewChildren
      }
    },
    async fetchData() {
      const model = {
        groupId: this.groupCategory,
        id: this.value || null,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchGroupNewUpdated(model)
      this.dataGroupNewUpdated.forEach(element => {
        element.childrens = null
      })
      this.selected = this.value === '' || this.value === '0' ? null : this.value
    },
    selectChange(value) {
      this.$emit('input', value)
    },
    showAddModal() {
      this.$emit('addNewItem')
    },
    callTitle() {
      let iTitle = ''
      switch (this.groupCategory) {
        case 1: {
          iTitle = 'nhóm tin tức'
          break
        }
        default: {
          iTitle = ''
          break
        }
      }
      this.titleCategory = `Lựa chọn ${iTitle}`
      this.noTitleCategory = `Không có ${iTitle} con`
    },
  },
}
</script>
<style lang="scss" scoped>
.header-template {
  margin: 0px 10px;
  text-align: left;
  color: #227ff4;

  svg {
    fill: transparent !important;
  }
}

.btn-text-select {
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}

.border-item-select {
  border-bottom: 1px;
  border-color: #ebe9f1;
  width: 100%;
}
</style>

<style lang="scss">
.is-invalid {
  .vue-treeselect__control {
    border-color: #ea5455;
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
  }
}

.vue-treeselect__control {
  border: 1px solid #a6a8b0;
}
</style>
